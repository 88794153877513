/*
 * App Glojal CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
@import "@ionic/angular/css/flex-utils.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
// @import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
/* @import "@ionic/angular/css/palettes/dark.system.css"; */
@import "./theme/utility.scss";
@import "./theme/variables.scss";

* {
  font-family: "Inter", sans-serif;
  text-transform: unset;
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: "Inter", sans-serif;
}


div,
p {
  color: #f5e6fe;
}

h1 {
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  color: white
}

p {
  margin: 0;
}

a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

// .glass-morphism {
//   background-color: rgba(var(--ion-background-color-rgb), .3);
//   backdrop-filter: blur(4px);
// }

.keyboard-open {
  ion-content {
    --offset-top: 0px;
    --offset-bottom: 0px;
    --keyboard-offset: 290px;
  }
}

html.keyboard-closed {
  ion-content {
    --offset-top: 0px;
    --offset-bottom: 0px;
    --keyboard-offset: 0px !important;
  }
}

body {
  background-color: var(--ion-main-background-color) !important;
  --background-color: var(--ion-main-background-color) !important;
  background: var(--ion-main-background-color) !important;
  --background: var(--ion-main-background-color) !important;
}

.btn-primary-lg {
  --color: white;
  --background: #381464;
  --border-radius: 15px;
  --padding-top: 2rem;
  --padding-bottom: 2rem;
  --padding-left: 22px;
  --padding-right: 22px;
}


.btn-primary-sm {
  --background: #381464;
  --border-width: 1px;
  min-width: 110px;
  padding: 2px 0;
}

.light-purple-text {
  color: #d3a5ee;
}

.cursor,
ion-icon {
  cursor: pointer;
}

.color-white,
ion-icon,
ion-title,
ion-item {
  color: white !important;
}

.padding-25 {
  padding: 25px;
}

.height-webkit-fill {
  height: -webkit-fill-available;
}

.bg-gradient {
  background: var(--main-container-linear-gradient);
}

.form-content-container {
  padding: 20px;
  border-radius: 12px;
  color: white;
}

.form-container-border {
  padding: 1px;
  border-radius: 12px;
  background: var(--main-container-linear-gradient);
}

.form-input-wrapper {
  border-radius: 30px;
  padding: 1px;
  background: linear-gradient(45deg, #491b59, #484848);
}

.form-input-color {
  border-radius: 30px;
  background-color: #1a1221;

  ion-input {
    --border-color: none !important;
    --highlight-color: var(--ion-color-primary) !important;
  }
}

.form-input {
  background: linear-gradient(45deg, #491b59, #484848);
}

.form-input-color-r12 {
  background-color: #1a1221;
  height: 35px;

  ion-input {
    --border-color: none !important;
    --highlight-color: var(--ion-color-primary) !important;
  }
}

.textarea-input {
  background-color: #1a1221;

  ion-textarea {
    --border-color: #1a1221;
    ;
    --highlight-color: var(--ion-color-primary) !important;
  }

}

ion-checkbox {
  --size: 26px;
  --checkbox-background-checked: var(--ion-color-primary);
  --border-radius: 6px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #8E8A91;
  &.rounded{
    --border-radius: 25px;    
    --border-color: var(--ion-color-primary);
    --checkbox-background: none;
  }
  &.nocheckmark{
    --checkmark-color: transparent;
  }
}

.background-light {
  background-color: var(--ion-background-color) !important;
  --background-color: var(--ion-background-color) !important;
  background: var(--ion-background-color) !important;
  --background: var(--ion-background-color) !important;
}

.main-background {
  background-color: var(--ion-main-background-color) !important;
  --background-color: var(--ion-main-background-color) !important;
  background: var(--ion-main-background-color) !important;
  --background: var(--ion-main-background-color) !important;
}

.dark-bg-main {
  --background: var(--ion-main-background-color) !important;
}

.gradient-text {
  background: -webkit-linear-gradient(to right,
      var(--ion-color-primary),
      var(--ion-color-secondary));
  background: linear-gradient(to right,
      var(--ion-color-primary),
      var(--ion-color-secondary));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

ion-button {
  --box-shadow: none;
  --background-hover: none;
}

ion-button.bg-dark{
  --background: #220b515e;
}

.button-color {
  --background: linear-gradient(90deg, #230038 30%, #002026 100%);
}

.button-background {
  background: linear-gradient(90deg, #230038 30%, #002026 100%);
  padding: 2px;
  border-radius: 40px;
  overflow: hidden;
}
.d-none{
  display: none;
}
.display {
  display: block;
}
.button-border {
  border-radius: 40px;
  padding: 1px;
  background: linear-gradient(90deg, #9900f7 30%, #00cff0 100%);
}

.cancel-outline-btn {
  --border-color: red;
  color: white;
}

.small-modal {
  --height: fit-content;
  --border-radius: 20px;
}

.popup-modal {
  // --width: 600px;
  backdrop-filter: blur(10px);
  --border-radius: 25px;
  --border-color: var(--ion-color-primary);
  --border-style: solid;
  --border-width: 1px;
  // background: rgba(0, 0, 0, 0.58);
  &.img-bg{
    --background: url('/assets/images/forgot_password_bg.png')
  }
  --background: none;
  .socielite-input {
    margin-top: 3px;
    background: rgb(255 255 255 / 22%)
  }
}

.h-fit-content{
  --height: fit-content;
}

.scroll-modal-container {
  height: 85vh;
  overflow-y: scroll;
}

.transparent-modal {
  opacity: 0.969;
}

ion-split-pane {
  --side-width: 300px;
  --border: 0px;
}

.fs-title {
  font-size: clamp(32px, 2.2vw, 80px);
}

.fs-heading {
  font-size: clamp(28px, 1.4vw, 50px);
}

.fs-md {
  font-size: clamp(22px, 0.9vw, 35px);
}

.fs-sm {
  font-size: clamp(10px, 0.8vw, 26px);
}

.fs-xs {
  font-size: clamp(7px, 0.5vw, 16px);
}

.fs-12 {
  font-size: 12px;
}

.bold {
  font-weight: bold !important;
}

.my-flex,
.flex {
  display: flex;
}

.my-flex-row {
  flex-direction: row;
}

.my-flex-column {
  flex-direction: column;
}

apx-chart>div {
  margin-inline: -16px;
}

.cursor-pointer,
.pointer {
  cursor: pointer;
}

ion-modal.glace-modal {
  --max-height: 96%;
  --border-radius: 10px;
  --width: 92%;
  --max-width: 800px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --height: auto;
  --background: #3b333399;
  backdrop-filter: blur(4px);
  
  &.gradient-bg {
    --background: linear-gradient(45deg, #221f28 5%, #541b86 22% 22%, #3a0b85 59%, #541b86 100%);
  }
  
  .wrapper{
      margin-bottom: 10px;
  }
  .scrollable {
    height: 40vh;
    max-height: 40vh;
  }
}

// table {
//   tbody {
//     tr {
//       border-top: 2px solid #dedede2e;
//       padding: .5rem 0;
//     }
//   }
// }


.pending {
  background-color: #c06814;
}

.skyblue {
  background-color: #0c98ac;
}

.green {
  background-color: green;
}

.purple {
  background-color: #7B00C7;
}

.closed {
  background-color: #c80f00;
}

.tb-status {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  display: inline-block;
  width: 125px;
  text-align: center;
  border-radius: 10px;
  text-transform: capitalize;

  &.pending {
    background-color: rgba(192, 103, 20, 0.7);
    color: rgb(255 129 12);
  }

  &.skyblue {
    background-color: rgba(12, 151, 172, 0.7);
    color: rgb(39 206 231);
  }

  &.green {
    background-color: rgb(0 139 0 / 79%);
    color: rgba(0 233 0);
  }

  &.purple {
    background-color: rgba(123, 0, 199, 0.2);
    color: rgb(190 100 245);
  }

  &.closed {
    background-color: rgba(200, 17, 0, 0.2);
    color: rgb(200, 17, 0);
  }
}

table.dataTable thead>tr>th[class*="dt-order"] span.dt-column-order,
table.dataTable thead>tr>td[class*="dt-order"] span.dt-column-order {
  display: none;
}
table.dataTable.show-order thead>tr>th[class*="dt-order"] span.dt-column-order,
table.dataTable.show-order thead>tr>td[class*="dt-order"] span.dt-column-order {
  display: block;
}

.card-wrapper {
  // margin: 0 1rem;
  background: linear-gradient(to bottom, #9900f7,
      #00cff0);
  padding: .25px;

  ion-card {
    margin-block: 1.75px;
    margin-inline: 1.75px;
  }
}
.card-wrapper-2 {
  // margin: 0 1rem;
  background: linear-gradient(to bottom, #9603f175,
      #a904f58c);
  padding: .25px;

  ion-card {
    margin-block: 1.75px;
    margin-inline: 1.75px;
  }
}

.flex-1 {
  flex: 1;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto;
}

.w-100 {
  width: 100% !important;
}

.bg-clear {
  background: none;
  --background: none;
}

.table-div[_ngcontent-ng-c189582005] {
  width: 100%;
  padding-bottom: 1rem !important;
  background-color: #160124 !important;
  font-weight: normal;
}

/* datatable new header bg */
table.bg-header.dataTable {
  thead {
    background: rgb(162 3 194 / 33%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-bottom: none;
    border: 2px solid transparent;

    th:last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    th:first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ffffff2e;

      td {
        padding: 8px 10px;
      }
    }
  }
}
table.bg-header.dataTable.small-row{
  tbody{
    td {
      padding: 2px 10px;
    }
  }
  
}

#custom-pagination, .custom-pagination, .dt-pager {
  display: flex;
  justify-content: end;
  padding-bottom: 12px;
  nav {
      button {
        border-radius: 50px;
        width: 40px;
        height: 40px;
        margin: 5px;
        color: white;
            background: none;
      }
      button.current{
        background: var(--ion-color-primary);
      }
      // button.previous, button.next {
      // }
  }
}

table.dataTable thead>tr>th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-ordering-desc span.dt-column-order:after {
  display: none;
}

.row-bordered-table {
  table.dataTable>tbody>tr:last-child>* {
    border-bottom: 1px solid rgba(141, 139, 174, 0.603);
  }

  .dt-length {
    margin-top: 8px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      background-color: var(--ion-main-background-color) !important;
      color: white !important;
    }
  }
}

.pagination-bg {
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      background-color: var(--ion-main-background-color) !important;
      color: white !important;
    }
  }
}

.not-row-bordered-table {
  .dt-length {
    margin-top: 8px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      background-color: var(--ion-main-background-color) !important;
      color: white !important;
    }
  }
}

.active {
  // --background: #9900f721;
  // background: linear-gradient(to right, #9900f721, #9800f707);
  backdrop-filter: blur(1px);
  border-radius: 12px;
  color: var(--ion-color-primary) !important;

  ion-icon {
    color: var(--ion-color-primary) !important;
  }
}

.modal-popup {
  --max-width: 96%;
  --min-width: 70%;
  --height: fit-content;
  --border-radius: 16px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.background-purple {
  --background: #1f0132;
  background: #1f0132;
  --border: 1px solid var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

.imgInput {
  box-shadow: none;
  width: 120px;
  cursor: pointer;

  ion-input {
    visibility: hidden;
  }

  ion-icon {
    font-size: 72px;
  }
}

.custom-segment {
  ion-segment {
    justify-content: flex-start;
    margin-bottom: 20px;
    height: 3rem;

    ion-segment-button {
      background: #12001D;
      border-radius: 15px;
      text-transform: uppercase;
      font-weight: bold;
      --indicator-height: 0px;
    }
  }
}

ion-segment-button.segment-active {
  background: var(--ion-segment-linear-gradient) !important;
  color: white !important;
}

.profile {
  cursor: pointer;
  padding: 7px 4px;
  border-radius: 10px;
  border: 1px solid rgb(113 94 94 / 60%);

  .profile-info {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;

    p {
      font-size: 12px;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.disconnect-btn {
  --background: #e0c8dc29;
  --background-color: #e0c8dc29;
}

@media screen and (max-width: 576px) {
  .modal-popup {
    --height: 88%;
  }

  app-create-client {
    overflow-y: auto;
  }

  .popup-modal {
    --height: max-content;
  }

  .back {
    padding: 0 !important;
  }

  .p-5 {
    padding: 5px;
  }

}


.br-primary {
  border: 1.5px solid var(--ion-color-primary);
}

.br-primary-modal {
  --border-color: var(--ion-color-primary);
  --border-width: 1.5px;
  --border-style: solid;
}


.small-margin {
  margin: .5px;
}

.gradient-border {
  background: linear-gradient(to bottom, #9900f7,
      #00cff0);
  padding: .5px;
}

ion-footer {
  box-shadow: none;
}

.stage-active {
  background-color: #371262;
}

.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
.tox .tox-toolbar-overlord .tox-toolbar__primary {
  background-color: rgb(43 18 61 / 14%) !important;
}

.tox .tox-menubar+.tox-toolbar,
.tox .tox-menubar+.tox-toolbar-overlord,
.tox-toolbar__group {
  background-color: rgb(43 18 61 / 14%) !important;
}


.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
  background-color: rgb(43 18 61 / 14%) !important;
  // border-radius: 12px;
  overflow: hidden;
}

.tox .tox-editor-header>.tox-toolbar--scrolling,
.tox .tox-toolbar-overlord .tox-toolbar--scrolling:first-child {
  background-color: rgb(43 18 61 / 14%) !important;
;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary,
.tox .tox-tbtn--disabled,
.tox .tox-tbtn--disabled:hover,
.tox .tox-tbtn {
  background-color: rgb(43 18 61 / 14%) !important;
}

.tox .tox-statusbar {
  background-color: #0b0012 !important;
}

div.tox-statusbar,
.header-light {
  background-color: rgb(182 80 255 / 14%) !important;
}

.tox.tox-tinymce {
  &[role=application] {
    border: none !important;
  }

  .tox-edit-area {
    border-bottom: none;
    background-color: #f2f2f2f2;
    min-height: 200px;
  }

  .tox-statusbar {
    display: none;
    // border: 1px solid #9900f7;
    // border-bottom-left-radius: 12px;
    // border-bottom-right-radius: 12px;
  }
}
@media (max-width: 769px) {
  .tb-wrap {
    flex: 1 1 100%; /* Full-width for small screens */
    flex-wrap: wrap;
  }
}
.mx-20 {
  margin: 0 20px;
}

.unset {
  table {
    border-collapse: unset;
    border-spacing: 0;
  }
}

.eddit-btn-container {
  padding-top: 10px;
  padding-right: 10px;
  text-align: right;
}

.edit-btn {
  --border-radius: 100%;
  --border-width: 10px;
  --padding-top: 12px;
  --padding-bottom: 10px;
}

.edit-icon {
  width: 25px;
  height: 25px;
}

.center-item {
  display: grid;
  place-items: center;
}

.adjust-right {
  display: grid;
  justify-items: end;
}

.hr_white {
  height: 1px;
  background-color: white;
  margin: 0;
}

ion-popover.faq {
  --max-width: 200px;
  --width: 120px
}

.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}
.w-15 {
  width: 15%;
}
.w-70 {
  width: 90%;
}

.h-50 {
  height: 50%;
}

// .ngx-file-drop__drop-zone[_ngcontent-ng-c2301008647] {
//   border: 0 !important;
// }
.minheight-400 {
  min-height: 400px;
}

.ngx-file-drop__drop-zone[_ngcontent-ng-c2301008647] {
  min-height: 200px !important;
  display: flex;
  border: none !important;
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  background: rgb(255 255 255 / 26%);
}

.erroMessage {
  color: red;
  font-weight: bold;
}

.inline-b {
  display: inline-block;
}

.modal-bg {
  --background: var(--ion-modal-form-bg-color);
}

.image-fit-container {
  object-fit: cover;
}

.input-padding-left,
.responsive-padding-left {
  padding-left: 1rem;
}

.input-padding-right,
.responsive-padding-right {
  padding-right: 1rem;
}


ion-chip.ion-chip-success {
  background-color: #98CBA6;
  color: #018124;
  font-weight: bold;

  ion-icon {
    color: #018124 !important;
  }
}

ion-chip.ion-chip-danger {
  background-color: #CB9898;
  color: #B80000;
  font-weight: bold;

  ion-icon {
    color: #B80000 !important;
  }
}

.dynamic-padding-right {
  padding-right: 1rem !important;
  --padding-right: 1rem !important;
}

.dynamic-padding-left {
  padding-left: 1rem !important;
  --padding-left: 1rem !important;
}

.dynamic-margin-right {
  margin-right: 1rem !important;
  --margin-right: 1rem !important;
}

.dynamic-margin-left {
  margin-left: 1rem !important;
  --margin-left: 1rem !important;
}

.btn-common{
  --background: #2b123db6;
}

@media screen and (max-width:576px) {

  .push-col-below {
    order: 2;
  }

  .reverse-col-order {
    flex-direction: column-reverse;
  }

  .input-padding-right,
  .dynamic-padding-right {
    padding-right: 0 !important;
  }

  .input-padding-left,
  .dynamic-padding-left {
    padding-left: 0 !important;
  }

  .dynamic-margin-right {
    margin-right: 0 !important;
    --margin-right: 0 !important;
  }

  .dynamic-margin-left {
    margin-left: 0 !important;
    --margin-left: 0 !important;
  }

  .sm-margin-bottom {
    margin-bottom: 1rem !important;
    --margin-bottom: 1rem !important;
  }
}

.dot {
  height: 5px;
  width: 5px;
  background-color: var(--ion-color-success);
  border-radius: 50%;
  display: inline-block;
}

.form-card {
  width: 100%;
  max-width: 650px
}

.sm-dropdown {
  flex: .76;
}

.card-image-wrapper {
  /* Adjust height as needed */
  overflow: hidden;
}

.card-image-wrapper img {
  object-fit: cover;
  /* Ensures the image maintains its aspect ratio */
}

.invisible {
  display: none;
}

.card-background {
  background-color: #160124;
  --background: #160124;
}

.app-custom-avatars {
  width: 100%;
}

.alert-modal {
  --max-width: 400px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.color-danger {
  color: var(--ion-color-danger);
  font-weight: bolder;

}

.socielite-input {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.12);
  padding: 8px; /* Add padding to match layout */
  &.bordered{
    border: 1px solid rgba(152, 0, 247, 0.28);
  }
  &.small {
    height: 35px;
    display: flex;
    align-items: center;
  }
  ion-textarea, ion-input {
    --color: #ffffff;
    --placeholder-color: #aaaaaa;
    --highlight-color-focused: #fff;
  }
}
.select{
  --color: white;
  border-radius: 32px;
  background: #25173db2;
  ion-select{
    --padding-start:20px;
    --padding-end: 20px;
  }
  &.bordered{
    border: 1px solid rgba(152, 0, 247, 0.28);
  }
}

.custom-select {
  ion-select-popover {
    ion-list {
      ion-item {
        --background: rgba(var(--ion-color-primary-rgb), 0.25);
        --color: #fff;
        --inner-border-width: 0;
      }
    }
  }
}

.invalid {
  border: 1px solid red !important;
}

.error-message {
  color: red;
  text-align: left;
  margin-top: 5px;
}

input.dt-input {
  background: rgba(255, 255, 255, 0.12) !important;
  border: 2px solid rgba(152, 0, 247, 0.28) !important;
  padding: 12px !important;
  border-radius: 8px !important;

  &::placeholder {
    color: white;
  }
}
.outside-search{
  input.dt-input{
    display: none;
  }
}
div.dt-container select.dt-input {
  background: rgba(255, 255, 255, 0.12) !important;
  border: none;
}

div.dt-container select.dt-input option {
  background-color: var(--ion-color-primary);
}

div.dt-container .dt-paging .dt-paging-button,
.current,
.previous,
.next {
  border: none !important;
}

div.dt-container .dt-paging .dt-paging-button:not(.disabled) {
  color: white;
}

div.dt-container .dt-paging .dt-paging-button.current {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: white;
}

div.dt-container .dt-paging .dt-paging-button:hover {
  background: var(--ion-color-primary) !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex.two-columns {
  flex-wrap: wrap;

  // gap: 16px;
  & > div:first-child {
    flex: 2 1 800px;
  }

  & > div:last-child {
    flex: 1 1 200px;
  }

  &.equal-width {
    & > div {
      flex: 1 1 500px;
    }
  }
}

ion-segment {
  &.ios {
    border: 1px solid #45046d;
    border-radius: 50px;
    padding: 2px;
    --background: rgb(39 2 39 / 36%);

    ion-segment-button {
      --border-radius: 26px;
      min-width: 100px;
      --indicator-color: var(--ion-color-primary);
      --padding-top: 9px;
      --padding-bottom: 9px;
    }

    &:not(.dashboard):not([dashboard]) {
      text-transform: uppercase;

      ion-segment-button {
        --indicator-color: var(--segments-indicator-gradient);
      }
    }
  }
}

.status-border {
  border-radius: 4px;

  &.left {
    border-left: 4px solid;
  }

  &.top {
    border-top: 4px solid;
  }

  &.right {
    border-right: 4px solid;
  }

  &.bottom {
    border-bottom: 4px solid;
  }

  &.pending {
    border-color: #c06814;
  }

  &.skyblue {
    border-color: #0c98ac;
  }

  &.green {
    border-color: green;
  }

  &.purple {
    border-color: rgb(197 113 249);
  }

  &.closed {
    border-color: #c80f00;
  }
}

.status-button {
  text-transform: uppercase;

  &.pending {
    background-color: unset;
    --background: rgba(192, 103, 20, 0.7);
    --color: rgb(192, 103, 20);
    ion-icon{
      color: rgb(192, 103, 20) !important;
    }
  }

  &.skyblue {
    background-color: unset;
    --background: rgba(12, 151, 172, 0.7);
    --color: rgb(11 192 219);
    ion-icon{
      color: rgb(11 192 219) !important;
    }
  }

  &.green {
    background-color: unset;
    --background: rgb(0 139 0 / 79%);
    --color: rgba(0 233 0);
    ion-icon{
      color: rgb(0 233 0) !important;
    }
  }

  &.purple {
    background-color: unset;
    --background: rgb(157 0 253 / 57%);
    --color: rgb(197 113 249);
    ion-icon{
      color: rgb(197 113 249) !important;
    }
  }

  &.closed {
    background-color: unset;
    --background: rgb(171 22 8 / 70%);
    --color: rgb(227 33 15);
    ion-icon{
      color: rgb(227 33 15);
    }
  }
}

.dropzone-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 0px;
  text-align: center;

  .dz-default.dz-message {
    background-color: rgb(255 255 255 / 26%);
    text-align: center;
    border-radius: 8px;
    &:not([class*=padding-block]) {
      padding-block: 70px;
    }
  }

  .dz-button {
    background: none !important;
    color: white;
  }

  .dz-preview {
    &:not([class*=margin-top]) {
      margin-top: 16px;
      // padding-block: 70px;
    }

    &.dz-file-preview,
    &.dz-image-preview {
      display: flex;
      flex-direction: column;
      // padding: 7px;
      // border: 1px solid #ccc;
      // border-radius: 5px;
      // background-color: rgb(255 255 255 / 56%);
      position: relative;

      .details-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px;
        border: 1px solid #ccc;
        margin-bottom: 4px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.56);

        .dz-details {
          display: flex;
          align-items: center;
          gap: 10px;

          img[data-dz-thumbnail] {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 3px;
            border: 1px solid #ddd;
            display: none;
          }

          .dz-filename {
            span[data-dz-name] {
              font-weight: bold;
              font-size: 14px;
            }
          }

          .dz-size {
            font-size: 10px;
            color: #f7f1f1da;
          }
        }

        .dz-remove-button {
          border-radius: 50px;
          width: 25px;
          height: 25px;
          background: white;

          span[data-dz-remove] {
            font-size: 15px;
            color: red;
            cursor: pointer;

            &:hover {
              color: darkred;
            }
          }
        }
      }

      .status-message {
        display: none;
        align-items: center;
        justify-content: space-between;

        .dz-error-message {
          span[data-dz-errormessage] {
            font-size: 12px;
            color: #d9534f;
          }
        }

        .dz-error-mark {
          display: none;

          span {
            color: #d9534f;
            font-size: 18px;
          }
        }
      }

      .upload-progress {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dz-progress {
          width: 100%;
          height: 4px;
          background: #e6e6e6;
          border-radius: 4px;
          overflow: hidden;

          .dz-upload {
            display: block;
            height: 100%;
            background-color: #5cb85c;
            transition: width 0.3s ease-in-out;
          }
        }

        .dz-success-mark {
          display: none;

          span {
            color: #5cb85c;
            font-size: 15px;
          }
        }
      }

      &.dz-error {
        .status-message {
          display: flex;
        }

        .upload-progress {
          display: none;
        }
      }

      &.dz-success.dz-complete {
        .upload-progress {
          .dz-success-mark {
            display: block;
          }
        }
      }
    }

  }

}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

.grab-to-pan-grab {
  cursor: grab, move !important;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important;
}

.grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
  cursor: inherit !important;
}

.grab-to-pan-grab:active,
.grab-to-pan-grabbing {
  cursor: grabbing, move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}


.member {
  background: #2b123db6;
  ion-icon{
      font-weight: bolder;
  }
}

.scrollable{
  max-height: 500px;
  overflow-x: hidden;
  overflow: visible;
  overflow-y: auto;
}

.bottom-border{
  border-bottom: 1px solid #6e6c6eb6;
}

.plyr {
  border-radius: 10px;
}