// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {
  --ion-color-primary: #9900f7;
  --ion-color-primary-rgb: 153, 0, 247;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #8700d9;
  --ion-color-primary-tint: #a31af8;

  --ion-color-secondary: #00cff0;
  --ion-color-secondary-rgb: 0, 207, 240;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #00b6d3;
  --ion-color-secondary-tint: #1ad4f2;

  --ion-color-tertiary: #f000cf;
  --ion-color-tertiary-rgb: 240, 0, 207;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d300b6;
  --ion-color-tertiary-tint: #f21ad4;

  --ion-color-stripe: #f000cf;
  --ion-color-stripe-rgb: 240, 0, 207;
  --ion-color-stripe-contrast: #000000;
  --ion-color-stripe-contrast-rgb: 0, 0, 0;
  --ion-color-stripe-shade: #d300b6;
  --ion-color-stripe-tint: #f21ad4;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #b590cc;
  --ion-color-medium-rgb: 181, 144, 204;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #9067aa;
  --ion-color-medium-tint: #b590cc;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-background-color: #160e20;
  --ion-background-color-rgb: 22, 14, 32;
  --ion-main-background-color: #0b0012;   
  --ion-main-background-color-rgb: 11, 0, 18;   
  --ion-modal-form-bg-color:#200434;
  --ion-modal-form-bg-color-rgb: 32, 4, 52;

  --main-container-linear-gradient: linear-gradient(
    90deg,
    #9900f7,
    #dc00f0,
    #c000f0
  );

  --segments-indicator-gradient: linear-gradient(
    90deg,
    #9800f775,
    #00f0c85d
  );


  --ion-segment-linear-gradient: linear-gradient(90deg, rgba(220, 0, 240, 0.3), rgba(153, 0, 247, 0.3));

  
  --form-input-text-color: #f5e6fe;
  --form-input-fontsize: 16px;

  --plyr-color-main: var(--ion-color-primary, #9800f775);
}

.ion-color-stripe {
	--ion-color-base: var(--ion-color-stripe);
	--ion-color-base-rgb: var(--ion-color-stripe-rgb);
	--ion-color-contrast: var(--ion-color-stripe-contrast);
	--ion-color-contrast-rgb: var(--ion-color-stripe-contrast-rgb);
	--ion-color-shade: var(--ion-color-stripe-shade);
	--ion-color-tint: var(--ion-color-stripe-tint);
}