@each $key in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30 {
    .gap-#{$key} {
        gap: #{$key}px !important;

        &.em {
            gap: #{$key}em !important;
        }
    }

    .blur-#{$key} {
        backdrop-filter: blur(#{$key}px) !important;
    }

    .font-size-#{$key} {
        font-size: #{$key}px !important;

        &.em {
            font-size: #{$key}em !important;
        }
    }

    @each $radius in (border-radius, border-top-left-radius, border-top-right-radius, border-bottom-left-radius, border-bottom-right-radius) {
        .#{$radius}-#{$key} {
            #{$radius}: #{$key}px;
            --#{$radius}: #{$key}px;

            &.em {
                #{$radius}: #{$key}em;
                --#{$radius}: #{$key}em;
            }
        }
    }

    @each $margin in (margin, margin-block, margin-inline, margin-top, margin-bottom, margin-left, margin-right) {
        @if $key ==0 {
            .#{$margin}-auto {
                #{$margin}: auto;
            }
        }

        .#{$margin}-#{$key} {
            #{$margin}: #{$key}px;

            &.em {
                #{$margin}: #{$key}em;
            }
        }
    }

    @each $padding in (padding, padding-block, padding-inline, padding-top, padding-bottom, padding-left, padding-right) {
        @if $key ==0 {
            .#{$padding}-auto {
                #{$padding}: auto;
            }
        }

        .#{$padding}-#{$key} {
            #{$padding}: #{$key}px;

            &.em {
                #{$padding}: #{$key}em;
            }
        }
    }
}

@for $i from 1 through 1000 {
    .max-width-#{$i} {
        max-width: #{$i}px;
    }
}